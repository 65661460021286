import { Spinner } from 'react-bootstrap'
import { TYPOGRAPHY_TYPES, Typography } from '@components/Typography'

export function Loading() {
  return (
    <div
      className='d-flex flex-column justify-content-center align-items-center'
      style={{
        height: 'calc(100vh - 26px - 60px)'
      }}
    >
      <Typography component={TYPOGRAPHY_TYPES.SUBTITLE} className='pb-4'>
        Cargando, por favor espere.
      </Typography>
      <Spinner animation='grow' />
    </div>
  )
}

import { Col, Container, Row } from 'react-bootstrap'
import Link from 'next/link'
import Widget from '@components/widget'
import { TYPOGRAPHY_TYPES, Typography } from '@components/Typography'
import { InstagramIcon } from '@components/icons'

export const Widgets = ({ className = '', dark }) => {
  return (
    <div
      className={`d-flex justify-content-center tt-footer-col tt-color-scheme-0${dark ? 3 : 1} ${className}`}
    >
      <Container style={{ margin: 0, paddingLeft: '24px', paddingRight: '24px' }}>
        <Row className='d-flex'>
          <Col md={6} lg={3}>
            <Widget title='INFORMACIÓN'>
              <ul className='tt-list'>
                <li>
                  <Link href={`/faq#como-comprar`}>
                    <Typography component={TYPOGRAPHY_TYPES.PRODUCT_TITLE} color='white'>
                      Cómo comprar
                    </Typography>
                  </Link>
                </li>
                <li>
                  <Link href={`/faq#cambio-y-devoluciones`}>
                    <Typography component={TYPOGRAPHY_TYPES.PRODUCT_TITLE} color='white'>
                      Cambio y devoluciones
                    </Typography>
                  </Link>
                </li>
                <li>
                  <Link href={`/faq#metodos-de-pago`}>
                    <Typography component={TYPOGRAPHY_TYPES.PRODUCT_TITLE} color='white'>
                      Métodos de pago
                    </Typography>
                  </Link>
                </li>
                <li>
                  <Link href={`/faq#gift-cards`}>
                    <Typography component={TYPOGRAPHY_TYPES.PRODUCT_TITLE} color='white'>
                      Gift Cards
                    </Typography>
                  </Link>
                </li>
              </ul>
            </Widget>
          </Col>

          <Col md={6} lg={6}>
            <Widget title='DÓNDE ESTAMOS'>
              <ul className='tt-list'>
                <li>
                  <Typography component={TYPOGRAPHY_TYPES.PRODUCT_TITLE} color='white'>
                    Av. Luro 6106 - Gregorio de Laferrere
                  </Typography>
                </li>
                <li>
                  <iframe
                    loading='lazy'
                    src='https://www.google.com/maps/d/u/0/embed?mid=1X_00DyKsyV-Igs_Q07B37NuVAGTVw38&ehbc=2E312F&noprof=1'
                    width='100%'
                    height='480'
                  ></iframe>
                </li>
              </ul>
            </Widget>
          </Col>

          <Col md={6} lg={3}>
            <Widget title='CONTACTO'>
              <ul className='tt-list'>
                <li>
                  <address>
                    <p>
                      <Link href='mailto:parisjoyeria@contacto.com'>
                        <Typography component={TYPOGRAPHY_TYPES.PRODUCT_TITLE} color='white'>
                          parisjoyeria@contacto.com
                        </Typography>
                      </Link>
                    </p>
                  </address>
                </li>
                <li>
                  <Link
                    href='https://www.instagram.com/paris_joyeria/'
                    className='d-flex align-items-center'
                  >
                    <InstagramIcon height='20' width='20' />
                    <Typography
                      component={TYPOGRAPHY_TYPES.PRODUCT_TITLE}
                      color='white'
                      style={{ marginLeft: '4px' }}
                    >
                      paris_joyeria
                    </Typography>
                  </Link>
                </li>
              </ul>
            </Widget>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

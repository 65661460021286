export const TYPOGRAPHY_TYPES = Object.freeze({
  H1: 'H1',
  H2: 'H2',
  H3: 'H3',
  PRODUCT_TITLE: 'PRODUCT_TITLE',
  SUBTITLE: 'SUBTITLE',
  BODY: 'BODY',
  FOOTER: 'FOOTER',
  BREADCRUMB: 'BREADCRUMB',
  PRICE: 'PRICE',
  DISCOUNT_PRICE: 'DISCOUNT_PRICE',
  TERTIARY: 'TERTIARY',
  PRICE_M: 'PRICE_M',
  DISCOUNT_PRICE_M: 'DISCOUNT_PRICE_M'
})

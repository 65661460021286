import PropTypes from 'prop-types'
import { Widgets } from './components/widgets'

export const Footer = ({ className = '', widgets, dark }) => {
  return (
    <footer id='tt-footer' className={className}>
      {widgets && <Widgets dark={dark} />}
      {/* <Copyright dark={dark} /> */}
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string
}

import { useState, useEffect } from 'react'

export const useProgressiveImg = (lowQualitySrc, highQualitySrc) => {
  const [src, setSrc] = useState(lowQualitySrc)
  const [blur, setBlur] = useState(true)

  useEffect(() => {
    if (!highQualitySrc) {
      setBlur(false)
      return
    }

    if (lowQualitySrc) {
      const img = new Image()
      img.src = lowQualitySrc
      img.onload = () => {
        setSrc(lowQualitySrc)
        const highImg = new Image()
        highImg.src = highQualitySrc
        highImg.onload = () => {
          setSrc(highQualitySrc)
          setBlur(false)
        }
      }
    } else {
      setSrc(highQualitySrc)
      setBlur(false)
    }
  }, [lowQualitySrc, highQualitySrc])

  return [src, { blur }]
}

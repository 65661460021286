export const AlertIcon = props => {
  return (
    <svg
      width='13'
      height='12'
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.90429 1.68706C6.65351 1.22715 6.00368 1.22715 5.7529 1.68706L1.4036 9.66089C1.15961 10.1082 1.47711 10.6581 1.9793 10.6581H10.6779C11.1801 10.6581 11.4976 10.1082 11.2536 9.66089L6.90429 1.68706ZM4.60093 1.03526C5.35391 -0.345086 7.30342 -0.345086 8.05645 1.03526L12.4057 9.00909C13.1377 10.3511 12.1852 12 10.678 12H1.9794C0.472201 12 -0.480361 10.3511 0.251637 9.00909L4.60093 1.03526ZM5.66956 8.64477C5.66956 8.2742 5.96483 7.97346 6.32866 7.97346C6.69249 7.97346 6.98776 8.2742 6.98776 8.64477C6.98776 9.01534 6.69249 9.31607 6.32866 9.31607C5.96483 9.31607 5.66956 9.01534 5.66956 8.64477ZM6.98774 4.61823C6.98774 4.24766 6.69248 3.94693 6.32864 3.94693C5.96481 3.94693 5.66954 4.24766 5.66954 4.61823V6.6315C5.66954 7.00207 5.96481 7.3028 6.32864 7.3028C6.69248 7.3028 6.98774 7.00207 6.98774 6.6315V4.61823Z'
        fill='white'
      />
    </svg>
  )
}

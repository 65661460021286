import Link from 'next/link'
import { DEFAULT_PARIS_PAGE_TITLE } from '@utils/constant'
import { SafeImage } from '@components/SafeImage'
import * as Styled from './Logo.styled'

export const LOGO_SVG_URL = '/assets/images/logo.svg'

export const Logo = () => {
  return (
    <Styled.Container>
      <Link href='/'>
        <SafeImage
          width={100}
          height={80}
          src={LOGO_SVG_URL}
          alt={DEFAULT_PARIS_PAGE_TITLE}
          loading='eager'
        />
      </Link>
    </Styled.Container>
  )
}

import Link from 'next/link'

export function DesktopNavOptions({ className = '', navData }) {
  return (
    <nav className={className}>
      <ul>
        {navData?.map(navItem => (
          <li
            key={navItem.id}
            className={`dropdown ${navItem.submenu ? 'tt-submenu' : ''} ${
              navItem.mega_menu ? 'megamenu' : 'tt-megamenu-col-01'
            }`}
          >
            <Link href={navItem.link}>{navItem.text}</Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

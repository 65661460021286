import { IoMdClose } from 'react-icons/io'
import Link from 'next/link'
import { useDisableBodyScroll } from '@hooks/useDisableBodyScroll'
import { TYPOGRAPHY_TYPES, Typography } from '@components/Typography'

export function DrawerNav({ className = '', drawerHandler, navData }) {
  useDisableBodyScroll(true)

  return (
    <nav className={`panel-menu mobile-main-menu mmitemopen ${className} w-100`}>
      <div className='mmpanels'>
        <div className='mmpanel mmopened mmcurrent' id='mm0'>
          <ul>
            <li
              className='mm-close-parent'
              style={{
                height: '60px',
                padding: '0px 24px',
                marginBottom: '40px'
              }}
            >
              <button className='mm-close' onClick={drawerHandler}>
                <IoMdClose size={20} />
              </button>
            </li>
            {navData.map(nav => (
              <li key={nav.id} style={{ paddingBottom: '30px' }}>
                <Link
                  href={nav?.link}
                  className={`pt-0 pb-0
                    ${nav?.submenu || nav?.mega_menu ? 'mm-next-level' : ''}`}
                  onClick={() => drawerHandler()}
                >
                  <Typography component={TYPOGRAPHY_TYPES.SUBTITLE} texttransform='capitalize'>
                    {nav?.text}
                  </Typography>
                </Link>
                {nav?.submenu &&
                  nav?.submenu.map(subitem => (
                    <ul key={subitem?.id}>
                      <li className='title'>{subitem?.title}</li>
                      {subitem?.lists.map((item, idx) => (
                        <li key={idx} className={item.badge}>
                          <Link href={item?.link}>{item?.text}</Link>
                        </li>
                      ))}
                    </ul>
                  ))}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export function Hamburger({ mobileNavbarHandler }) {
  return (
    <button className='tt-menu-toggle m-0 p-0' onClick={mobileNavbarHandler}>
      <svg
        width='20'
        height='14'
        viewBox='0 0 20 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <line x1='1' y1='1' x2='19' y2='1' stroke='black' strokeWidth='2' strokeLinecap='round' />
        <line x1='1' y1='7' x2='19' y2='7' stroke='black' strokeWidth='2' strokeLinecap='round' />
        <line x1='1' y1='13' x2='19' y2='13' stroke='black' strokeWidth='2' strokeLinecap='round' />
      </svg>
    </button>
  )
}

import styled from 'styled-components'

export const WebImage = styled.img`
  display: block;
  object-fit: ${({ objectfit }) => objectfit || 'contain'};
  max-width: 100%;
  width: ${({ width }) => width || 'initial'};
  height: ${({ height }) => height || 'initial'};
  filter: ${({ blur }) => (blur ? 'blur(2px)' : 'none')};
  transition: ${({ blur }) => (blur ? 'none' : 'filter 0.3s ease-out')};
`

import styled, { css } from 'styled-components'
import { truncateText } from '@utils/styled'

const TYPOGRAPHY_CONFIG = {
  H1: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '24px'
  },
  H2: {
    fontSize: '20px',
    lineHeight: '24px'
  },
  H3: {
    fontSize: '16px',
    lineHeight: '20px'
  },
  PRODUCT_TITLE: {
    fontSize: '14px',
    lineHeight: '18px'
  },
  SUBTITLE: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px'
  },
  BODY: {
    fontSize: '12px',
    lineHeight: '20px'
  },
  FOOTER: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px'
  },
  BREADCRUMB: {
    fontSize: '12px',
    lineHeight: '20px'
  },
  PRICE: {
    fontFamily: 'var(--font-baskerville)',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px'
  },
  DISCOUNT_PRICE: {
    fontFamily: 'var(--font-baskerville)',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '16px',
    textDecoration: 'line-through',
    color: 'rgb(154, 154, 154)',
    alignContent: 'center'
  },
  TERTIARY: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',
    textDecoration: 'underline'
  },
  PRICE_M: {
    fontFamily: 'var(--font-baskerville)',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '28px'
  },
  DISCOUNT_PRICE_M: {
    fontFamily: 'var(--font-baskerville)',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '28px',
    textDecoration: 'line-through',
    color: 'rgb(154, 154, 154)'
  }
}

const typographyStyles = ({ type }) => css`
  ${type &&
  css`
    font-family: ${TYPOGRAPHY_CONFIG[type].fontFamily || 'var(--font-gotham)'};
    font-size: ${TYPOGRAPHY_CONFIG[type].fontSize};
    font-weight: ${TYPOGRAPHY_CONFIG[type].fontWeight};
    line-height: ${TYPOGRAPHY_CONFIG[type].lineHeight};
    text-decoration: ${TYPOGRAPHY_CONFIG[type].textDecoration};
    color: ${TYPOGRAPHY_CONFIG[type].color};
    align-content: ${TYPOGRAPHY_CONFIG[type].alignContent};
  `}
`

export const Typography = styled.span`
  ${typographyStyles}
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  font-weight: ${({ fontweight }) => fontweight};
  text-transform: ${({ texttransform }) => texttransform};
  ${({ truncatetext }) => truncatetext && truncateText(truncatetext)}
`

import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

export function useLoadingOnNavigation() {
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const lastPathnameRef = useRef(router.pathname)

  const handleRouteChangeStart = url => {
    const { pathname } = new URL(url, window.location.origin)

    if (pathname !== lastPathnameRef.current) {
      setLoading(true)
    }
  }

  const handleRouteChangeComplete = url => {
    const { pathname } = new URL(url, window.location.origin)
    lastPathnameRef.current = pathname
    setLoading(false)
  }

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeComplete)
    }
  }, [router.events])

  return { loading }
}

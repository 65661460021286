import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  *:focus {
    outline: none;
    box-shadow: none;
  }
  
  html, body, #__next, #application {
    height: 100%;
    background-color: #fff;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 1px;
  }

  button {
    letter-spacing: 1px;
  }
  
  #__next {
    isolation: isolate;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  img[draggable="false"] {
    -webkit-touch-callout: none;
  }

  input[type="radio"], input[type="checkbox"] {
    accent-color: rgba(0, 0, 0, 1);
  }

  a {
    text-decoration: none;
    outline: none;
    color: black;
  }

  a:hover {
    outline: none;
    text-decoration: none;
    outline: none;
  }

  a:active,
  a:visited,
  a:focus {
    text-decoration: none;
    outline: none;
  }
`

export default GlobalStyle

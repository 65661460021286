export function isValidURL(stringValue) {
  if (!stringValue) {
    return
  }
  try {
    const url = new URL(stringValue)
    return url.protocol === 'http:' || url.protocol === 'https:'
  } catch {
    return false
  }
}

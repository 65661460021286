import { DesktopNavOptions } from './DesktopNavOptions'

export function Navbar({ className = '', hoverStyleClass = '', navData }) {
  return (
    <div
      className={`tt-desktop-parent-menu tt-parent-box ${className} d-flex h-100 align-items-end`}
    >
      <div className={`tt-desktop-menu ${hoverStyleClass}`}>
        <DesktopNavOptions navData={navData} />
      </div>
    </div>
  )
}

import { ThemeProvider } from 'styled-components'
import { StyleSheetManager } from 'styled-components'
import { Toaster } from 'react-hot-toast'
import GlobalStyle from 'src/theme/globalstyles'
import localFont from 'next/font/local'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { Analytics } from '@vercel/analytics/react'
import { useLoadingOnNavigation } from '@hooks/useLoadingOnNavigation'
import { PageProvider } from '@global/PageProvider'
import { DrawerProvider } from '@global/DrawerProvider'
import isPropValid from '@emotion/is-prop-valid'
import { Loading } from '@components/Loading'
import { Layout } from '@components/Layout'
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary'
import '@assets/scss/style.scss'

/**
100 Thin
200 Extra Light
300 Light
400 Normal
500 Medium
600 Semi Bold
700 Bold
800 Extra Bold
900 Ultra Bold / Black
 */

// Define Baskerville and Gotham fonts
const baskerville = localFont({
  src: [
    {
      path: '../theme/fonts/Baskerville/Baskerville-Regular.ttf',
      weight: 'normal',
      style: 'normal'
    },
    {
      path: '../theme/fonts/Baskerville/Baskerville-Bold.ttf',
      weight: 'bold',
      style: 'normal'
    },
    {
      path: '../theme/fonts/Baskerville/Baskerville-Italic.ttf',
      weight: 'normal',
      style: 'italic'
    }
  ],
  variable: '--font-baskerville'
})

const gotham = localFont({
  src: [
    {
      path: '../theme/fonts/Gotham/gothambook.woff2',
      style: 'normal'
    },
    {
      path: '../theme/fonts/Gotham/gothambookitalic.woff2',
      style: 'italic'
    },
    {
      path: '../theme/fonts/Gotham/gothambold.woff2',
      weight: 'bold',
      style: 'normal'
    },
    {
      path: '../theme/fonts/Gotham/gothambolditalic.woff2',
      weight: 'bold',
      style: 'italic'
    }
  ],
  variable: '--font-gotham'
})

export default function App({ Component, pageProps }) {
  const { loading } = useLoadingOnNavigation()

  return (
    <main className={`${baskerville.variable} ${gotham.variable}`}>
      <ErrorBoundary>
        <PageProvider>
          <ThemeProvider theme={{}}>
            <GlobalStyle />
            <StyleSheetManager shouldForwardProp={prop => isPropValid(prop)}>
              {loading ? (
                <Loading />
              ) : (
                <DrawerProvider>
                  <Layout>
                    <Toaster />
                    {!!Component && <Component {...pageProps} />}
                    <Analytics />
                    <SpeedInsights />
                  </Layout>
                </DrawerProvider>
              )}
            </StyleSheetManager>
          </ThemeProvider>
        </PageProvider>
      </ErrorBoundary>
    </main>
  )
}

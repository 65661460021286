import { createContext, useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { getPageConfig } from '@utils/pageConfig'
import { DEFAULT_PARIS_PAGE_CONFIG } from '@utils/constant'

const PageContext = createContext({
  pageConfig: DEFAULT_PARIS_PAGE_CONFIG
})

export const PageProvider = ({ children }) => {
  const [pageConfig, setPageConfig] = useState(DEFAULT_PARIS_PAGE_CONFIG)
  const router = useRouter()

  useEffect(() => {
    const { categorySlug, productSlug } = router.query

    if (productSlug) {
      const productPageConfig = getPageConfig({ slug: categorySlug })

      setPageConfig(productPageConfig)
    } else if (categorySlug || router.pathname === '/') {
      const newPageConfig = getPageConfig({ slug: categorySlug })

      setPageConfig(newPageConfig)
    }
  }, [router.pathname, router.query])

  return <PageContext.Provider value={{ pageConfig }}>{children}</PageContext.Provider>
}

export const usePageContext = () => {
  const context = useContext(PageContext)
  if (!context) {
    throw new Error('usePageContext must be used within a PageProvider')
  }
  return context
}

import { useEffect } from 'react'

/**
 * Hook to disable the body scroll Y
 * @param {boolean} disable
 */
export function useDisableBodyScroll(disable) {
  useEffect(() => {
    if (disable) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [disable])
}

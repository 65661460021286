import { createContext, useContext, useState, useEffect } from 'react'
import { useTransition, animated } from '@react-spring/web'

const overlayConfig = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  config: { duration: 300 }
}

const drawerConfig = {
  from: { x: '-100%' },
  enter: { x: '0%' },
  leave: { x: '-100%' },
  config: { duration: 300 }
}

const DrawerContext = createContext({
  openDrawer: () => {},
  closeDrawer: () => {},
  isDrawerOpen: false,
  DrawerContent: null,
  hasOverlay: false
})

export const DrawerProvider = ({ children }) => {
  const [drawerState, setDrawerState] = useState({
    isOpen: false,
    DrawerContent: null,
    hasOverlay: false
  })

  const openDrawer = ({ component, hasOverlay }) => {
    setDrawerState({
      isOpen: true,
      DrawerContent: component,
      hasOverlay
    })
  }

  const closeDrawer = () => {
    setDrawerState(prevState => ({
      ...prevState,
      isOpen: false
    }))
  }

  useEffect(() => {
    if (!drawerState.isOpen) {
      const timer = setTimeout(() => {
        setDrawerState(prevState => ({
          ...prevState,
          DrawerContent: null,
          hasOverlay: false
        }))
      }, 300) // Duration of the exit animation
      return () => clearTimeout(timer)
    }
  }, [drawerState.isOpen])

  const store = {
    openDrawer,
    closeDrawer,
    isDrawerOpen: drawerState.isOpen,
    DrawerContent: drawerState.DrawerContent
  }

  const overlayTransition = useTransition(drawerState.isOpen && drawerState.hasOverlay, {
    from: overlayConfig.from,
    enter: overlayConfig.enter,
    leave: overlayConfig.leave,
    config: overlayConfig.config
  })

  const drawerTransition = useTransition(drawerState.isOpen, {
    from: drawerConfig.from,
    enter: drawerConfig.enter,
    leave: drawerConfig.leave,
    config: drawerConfig.config
  })

  return (
    <DrawerContext.Provider value={store}>
      {children}
      {overlayTransition((style, item) =>
        item ? (
          <animated.div style={style} className='drawer-overlay' onClick={closeDrawer} />
        ) : null
      )}
      {drawerTransition((style, item) =>
        item ? (
          <animated.div
            style={style}
            className={`drawer-container ${!drawerState.hasOverlay && 'full-width'}`}
          >
            {drawerState.DrawerContent}
          </animated.div>
        ) : null
      )}
    </DrawerContext.Provider>
  )
}

export const useDrawer = () => {
  const context = useContext(DrawerContext)
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider')
  }
  return context
}

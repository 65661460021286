import { useState, useEffect } from 'react'

export const MOBILE_BREAKPOINT = 790

export function useIsMobileLayout() {
  const [isMobileLayout, setIsMobileLayout] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileLayout(window.innerWidth <= MOBILE_BREAKPOINT)
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      handleResize()
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return isMobileLayout
}

import { css } from 'styled-components'

export const hideScrollbar = css`
  &::-webkit-scrollbar {
    display: none;
  }
`

export const truncateText = lines => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`

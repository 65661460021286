var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cd6be2c8d93b4e0b2b9fecba3c51e5283d374364"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { isProduction } from '@utils/isProduction'
import * as Sentry from '@sentry/nextjs'
import { rewriteFramesIntegration } from '@sentry/integrations'
import pkg from './package.json'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (isProduction) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1,
    attachStacktrace: true,
    environment: process.env.NODE_ENV,
    integrations: [
      rewriteFramesIntegration({
        root: global.__rootdir__
      })
    ],
    release: pkg.version
  })
}

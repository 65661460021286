// CONTAINER FLUID: AMOUNT OF PRODUCTS PER PAGE
export const CONTAINER_FLUID_PRODUCTS_PER_PAGE = 12

// CONTAINER NOT FLUID: AMOUNT OF PRODUCTS PER PAGE
export const NOT_CONTAINER_FLUID_PRODUCTS_PER_PAGE = 8

export const DEFAULT_PARIS_PAGE_TITLE = 'Paris Joyería - Relojería'

// ContentTypes from Contentful
export const WATCHES_PRODUCT = 'watchesProduct'
export const JEWELRY_BASE_PRODUCT = 'jewelryBase'

// TODO: See if this could be created on Contentful or find a better way to handle it
export const PAGE_CONFIG = {
  homepage: {
    contentTypes: [],
    pageTitle: DEFAULT_PARIS_PAGE_TITLE
  },
  relojeria: {
    contentTypes: [WATCHES_PRODUCT],
    title: 'Relojería',
    pageTitle: DEFAULT_PARIS_PAGE_TITLE,
    slug: 'relojeria',
    api: 'api/products/watches',
    productDetailApi: 'api/product/watches'
  },
  joyeria: {
    contentTypes: [JEWELRY_BASE_PRODUCT],
    title: 'Joyería',
    pageTitle: DEFAULT_PARIS_PAGE_TITLE,
    slug: 'joyeria',
    api: 'api/products/variant',
    productDetailApi: 'api/product/variant'
  }
}

export const DEFAULT_PARIS_PAGE_CONFIG = {
  title: DEFAULT_PARIS_PAGE_TITLE
}

export const DEFAULT_COUNTRY = {
  locale: 'es-AR',
  currency: 'ARS',
  symbol: '$',
  decimalSeparator: ','
}

import { isValidURL } from '@utils/check/isValidURL'
import * as Sentry from '@sentry/nextjs'
import { useProgressiveImg } from '@hooks/useProgressiveImg'
import * as Styled from './SafeImage.styled'

export function SafeImage({ src, previewSrc, ...props }) {
  const isFromAssets = src?.includes('/assets/images')

  const [currentSrc, { blur }] = useProgressiveImg(previewSrc, src)

  if (!isValidURL(src) && !isFromAssets) {
    Sentry.captureMessage(`Missing or invalid src (${src})`)
    return
  }

  return (
    <picture>
      <Styled.WebImage src={currentSrc} blur={blur} loading='lazy' {...props} />
    </picture>
  )
}

import Head from 'next/head'
import { toCapitalize } from '@utils/string/toCapitalize'
import { DEFAULT_PARIS_PAGE_TITLE } from '@utils/constant'
import { usePageContext } from '@global/PageProvider'
import { Header } from '@components/Header'
import { Footer } from '@components/Footer'

export function Layout({ children }) {
  const { pageConfig } = usePageContext()

  function getTitle() {
    if (pageConfig?.pageTitle) {
      return toCapitalize(pageConfig.pageTitle)
    }

    return DEFAULT_PARIS_PAGE_TITLE
  }

  return (
    <>
      <Head>
        <title>{getTitle()}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
        <meta name='description' content={DEFAULT_PARIS_PAGE_TITLE} />
      </Head>
      <div>
        <Header />
        {children}
        <Footer widgets dark />
      </div>
    </>
  )
}

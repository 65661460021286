import { useIsMobileLayout } from '@hooks/useIsMobileLayout'
import { useDrawer } from '@global/DrawerProvider'
import { MenuLinks as navData } from '@data/navbar'
import { Logo } from '@components/Logo'
import { DrawerNav } from '@components/DrawerNav'
import { Navbar } from './elements/Navbar'
import { Hamburger } from './elements/Hamburger'

export const Header = () => {
  const { openDrawer, closeDrawer } = useDrawer()

  const isMobileLayout = useIsMobileLayout()

  const mobileNavbarHandler = () => {
    openDrawer({
      component: <DrawerNav navData={navData} drawerHandler={closeDrawer} />
    })
  }

  return (
    <div className='header-container'>
      {isMobileLayout ? (
        <Hamburger mobileNavbarHandler={mobileNavbarHandler} />
      ) : (
        <Navbar navData={navData} />
      )}

      <Logo />
    </div>
  )
}

import { equalsIgnoreCase } from '@utils/check/equalsIgnoreCase'
import * as Styled from './Typography.styled'
import { TYPOGRAPHY_TYPES } from './constants'

export function Typography({ component = TYPOGRAPHY_TYPES.PRODUCT_TITLE, children, ...props }) {
  if (!children) {
    return null
  }

  const componentType = Object.keys(TYPOGRAPHY_TYPES).find(type =>
    equalsIgnoreCase(component, TYPOGRAPHY_TYPES[type])
  )

  if (!componentType) {
    return null
  }

  return (
    <Styled.Typography type={componentType} {...props}>
      {children}
    </Styled.Typography>
  )
}

import { useState } from 'react'
import { TYPOGRAPHY_TYPES, Typography } from '@components/Typography'

const Widget = ({ className = '', children, title }) => {
  const [collapsed, setCollapsed] = useState(false)

  const onCollapsedHandler = () => {
    setCollapsed(prevState => !prevState)
  }

  return (
    <div className={`tt-mobile-collapse ${className}`}>
      <h4
        className={`tt-collapse-title ${collapsed ? 'tt-open' : ''} position-relative d-block`}
        onClick={onCollapsedHandler}
      >
        <Typography component={TYPOGRAPHY_TYPES.FOOTER} color='white'>
          {title}
        </Typography>
      </h4>
      <div className='tt-collapse-content'>{children}</div>
    </div>
  )
}

export default Widget

import { Component } from 'react'
import { withRouter } from 'next/router'
import { isProduction } from '@utils/isProduction'
import * as Sentry from '@sentry/nextjs'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo })
    if (isProduction) {
      window.location.href = '/404'
    }
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)

import { PAGE_CONFIG } from '@utils/constant'

export function getPageConfig({ slug }) {
  if (!slug || typeof slug !== 'string') {
    return PAGE_CONFIG['homepage']
  }

  const normalizedSlug = slug.toLowerCase()

  return PAGE_CONFIG[normalizedSlug] || null
}
